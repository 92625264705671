<template>
    <client-only>
        <UHeader :links="links">
            <template #logo>
                <p class="tracking-tight">NomadRx</p>
            </template>
            <template #right>
                <UButton v-if="!isAuthenticated" label="Sign up" color="primary" to="/onboard" variant="solid" />
                <UButton v-if="!isAuthenticated && !$device.isMobile" label="Sign in" color="gray" to="/login"
                    variant="solid" />
                <UButton v-if="isAuthenticated" label="Account" color="primary" to="/account" variant="solid" />
            </template>
            <template #panel>
                <UHeaderPopoverLinks :links="links" />
                <UDivider class="my-4" />
                <div class="mt-4 space-y-2">
                    <UButton v-if="isAuthenticated" label="Sign out" icon="i-heroicons-arrow-left-end-on-rectangle"
                        @click="signOutUser" block variant="soft" />
                    <UButton v-else label="Create account" to="/onboard" block variant="solid" size="xl" />
                    <UButton v-if="!isAuthenticated" label="Sign in" to="/login" block variant="soft" size="xl" />

                </div>
            </template>
        </UHeader>
    </client-only>
</template>
<script setup lang="ts">

const { signOutUser } = useFirebaseAuth();

const route = useRoute();
console.log('route:', route)
const authStore = useAuthStore();
const { isAuthenticated } = storeToRefs(authStore);

const onboardStore = useOnboardStore();
const { partner } = storeToRefs(onboardStore);

const accountItems = [
    [{
        label: 'Go to homepage',
        icon: 'i-heroicons-home',
        to: "/",
    }], [{
        label: 'Sign out',
        icon: 'i-heroicons-arrow-left-end-on-rectangle',
        click: async () => {
            await signOutUser();
        }
    }]
]
const links = [{
    label: 'For Patients',
    to: '/',
    description: 'Find local doctors who take global insurance'
}, {
    label: 'For Providers',
    to: '/for-providers',
    description: 'Provide a great patient experience, get paid quickly, and accept international insurance'
}]
</script>