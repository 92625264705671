<!--TODO: check where this may be used-->

<template>
  <DefaultHeader />
  <UMain>
    <slot />
  </UMain>
</template>

<script setup>
import { ref } from "vue";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  CalendarIcon,
  CheckCircleIcon,
  LockOpenIcon,
  PencilIcon,
  MagnifyingGlassIcon,
  TagIcon,
  UserCircleIcon as UserCircleIconSolid,
} from "@heroicons/vue/24/solid/esm/index.js";
import SimpleCenteredFooter from "~/components/footer/SimpleCenteredFooter.vue";

const navigationStore = useNavigationStore();

const sidebarOpen = ref(false);
</script>